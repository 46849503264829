define('service-data',[
	'underscore',
	'util',
	'service',
	'manager-event'
], function (_, Util, Service, EventManager) {

	var credentialsOptions = {};
	var currentUserAccountID = null;

	try {
		var currentUserPayload = localStorage.getItem('calameo.user');

		if ( currentUserPayload )
		{
			try {
				var currentUser = JSON.parse(currentUserPayload);
				var accessToken = currentUser.session && currentUser.session.access_token;
				if ( accessToken )
				{
					function parseJwt(token) {
					    var base64Url = token.split('.')[1];
					    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
					    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
					        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
					    }).join(''));

					    return JSON.parse(jsonPayload);
					}
					console.warn('Found JWT credentials', accessToken.substr(0, 8) + '...' + accessToken.substr(-8));
					var jwt = parseJwt(accessToken);
					console.warn(jwt);
					console.warn('JWT Created:', (new Date(jwt.iat * 1000)).toISOString());
					console.warn('JWT Expires:', (new Date(jwt.exp * 1000)).toISOString());
					if ( jwt.exp * 1000 > Date.now())
					{
						credentialsOptions = {
							xhrFields: {
							   withCredentials: true
							},
							headers: {
								Authorization: 'Bearer ' + accessToken
							}
						};
					}
				}
				currentUserAccountID = ( currentUser.account || {} ).id;
			} catch (err) {
				console.warn('No current user found', currentUserPayload);
			}

			// Association
			var associationPayload = sessionStorage.getItem('calameo.account.association');
			if (associationPayload !== null) {
				try {
					association = JSON.parse(associationPayload);
					currentUserAccountID = association.id;
					console.warn('Using account association', currentUserAccountID);
				} catch (err) {
					console.warn('Invalid account association', associationPayload);
				}
			}
		}
	} catch (err) {
		console.warn('LocalStorage disabled');
	}

	// Set up all inheritable **BaseConfig** properties and methods.
	return Service.extend({}, {

		hasFallback: true,
		isFallback: false,

		_loader: $.ajax,
		_name: 'APIService',
		_id: '',

		_log: true,

		_promise: null,

		init: function(){
			if ( !this._promise || this._promise.state() !== 'pending' ) this._promise = $.Deferred();
		},

		load: function (options) {
			return this.request(options);
		},

		request: function(options){

			this.init();

			options || (options = {});

			var requestOptions = _.extend({
				accept: {
					'json': 'application/json'
				},
				success 	: this.onSuccess,
				error 		: this.onError,
				crossDomain : true,
				dataType    : 'json',
				context		: this
			}, options);

			// HTTPS ?
			requestOptions.url = Util.url.prepare( requestOptions.url );

			if ( _.isEmpty(requestOptions.url) || _.isUndefined(requestOptions.url) )
			{
				this.onError(null, 'Incorrect or missing API endpoint');
			}
			else
			{
				console.log('APIService => Requesting API:', requestOptions);

				this._requestOptions = requestOptions;

				this._loader(requestOptions);
			}

			return this._promise;
		},

		setID: function(id){
			this._id = ( id || '' );
		},
		getID: function(){
			return this._id;
		},

		reject: function(){
			this._promise.reject(910, 'Error accessing service', true);
		},

		onSuccess: function(json, textStatus, xhr)
		{
			json || (json = {});
			this.setID( json.id );

			if ( textStatus !== 'success' || !json || json.status !== 'ok' )
			{
				var code = json.content ? json.content.code || 0 : 0;
				var msg = json.content ? json.content.msg || 'Error accessing service' : 'Error accessing service';

				if ( this._log ) EventManager.trigger('debug', {type: 'error', msg: this._name + ' => Loading error ('+code+') ' + msg, metas: _.pick(this._requestOptions, 'url', 'data')});

				return this._promise.reject(code, msg);
			}

			console.info('APIService => Success:', json);

			this.set( json.content );

			this._promise.resolve();
		},
		onError: function(xhr, textStatus, errorThrown)
		{
			if ( this._log ) EventManager.trigger('debug', {type: 'error', msg: this._name + ' => Loading error ('+textStatus+':'+xhr.status+') ' + errorThrown, metas: _.pick(this._requestOptions, 'url', 'data')});

			// If there was an error loading the script, we try the fallback webservice
			if ( !this.isFallback && this.hasFallback && xhr )
			{
				this.isFallback = true;
				return this.load();
			}

			this.reject();
		},

		getCurrentUserAccountID: function() {
			return currentUserAccountID;
		},

		getCredentialOptions: function() {
			return credentialsOptions;
		}
	});

});

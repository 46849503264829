define('service-data-book',[
	'module',
	'underscore',
	'constants',
	'util',
	'service-data',
	'service-config-loader',
	'service-config-book',
	'urljs'
], function (module, _, $C, Util, DataService, LoaderConfigSrvc, BookConfigSrvc, URLObject) {
	URLObject || (URLObject = URL);
	
	// Set up all inheritable **BaseConfig** properties and methods.
	return DataService.extend({}, {

		_name: module.id,
		integrationsById: null,
		integrationsBySlug: null,

		_expires: Date.now(),
		_path: '',
		_signature: '',
		_refreshTimeout: null,

		load: function (options) {
			options || (options = {});
			var urls = Calameo.defaults.webservice.url.slice(0);
			var url = urls.shift();
			if ( this.isFallback ) url = urls.shift();
			return this.request( _.extend({
				url: url,
				data: _.extend({}, BookConfigSrvc.get())
			}, options, this.getCredentialOptions && this.getCredentialOptions()) );
		},

		onSuccess: function(json, textStatus, xhr)
		{
			this._expires = Util.ensureInt(xhr.getResponseHeader('x-calameo-hash-expires'));
			this._path = xhr.getResponseHeader('x-calameo-hash-path');
			this._signature = xhr.getResponseHeader('x-calameo-hash-signature');

			if ( this._expires === 0 ) {
				this._expires = Math.round(Date.now() / 1000) + 3600;
			}

			console.debug('URL signing (expires: %s, path: %s, signature: %s)', this._expires, this._path, this._signature);
			console.warn('Signature expires at %s', new Date(this._expires * 1000));
			DataService.onSuccess.apply(this, arguments);
			this.startRefreshTimeout();
		},

		onError: function(xhr, textStatus, errorThrown)
		{	
			// Ignore fallback and log when requests are cancelled by navigation?
			if ( xhr.status == 0 ) {
				this._promise.reject(410, 'Loading cancelled', true);
				return;
			}

			// Ignore fallback and log for 429 Too Many Requests
			if ( xhr.status == 429 ) {
				this._promise.reject(429, 'Too many requests', true);
				return;
			}

			// Ignore fallback and log for 403 Access denied
			if ( xhr.status == 403 ) {
				this._promise.reject(403, 'Access denied', true);
				return;
			}

			DataService.onError.apply(this, arguments);
		},

		expires: function(){
			return this._expires;
		},

		signature: function(){
			return this._signature;
		},

		startRefreshTimeout: function() {
			if ( !this.feature('urlsigning.enabled') || !this._expires ) return;
			this.stopRefreshTimeout();
			var delay = Math.max(300000, Math.round( ( this._expires * 1000 - Date.now() ) * 0.75 ));
			this._refreshTimeout = setTimeout($.proxy(function() {
				console.info('Refreshing signature')
				this.load();
			}, this), delay);
			console.debug('Waiting %ds before refreshing signature', Math.round(delay / 1000));
		},
		stopRefreshTimeout: function() {
			if ( !this._refreshTimeout ) return;
			clearTimeout(this._refreshTimeout);
			this._refreshTimeout = null;
		},

		account: function(key){
			return this.get('account.'+key);
		},

		feature: function(key){
			return this.get('features.'+key);
		},

		isPublisher: function() {
			return this.getCurrentUserAccountID() === this.account('id');
		},

		getIntegrationBySlug: function(slug) {
			if ( !this.integrationsBySlug ) {
				this.integrationsBySlug = ( this.feature('integrations.list') || [] ).reduce(function(dict, integration) {
					dict[integration.slug] = integration;
					return dict;
				}, {});
			}
			return this.integrationsBySlug[slug];
		},

		getIntegrationById: function(id) {
			if ( !this.integrationsById ) {
				this.integrationsById = ( this.feature('integrations.list') || [] ).reduce(function(dict, integration) {
					dict[integration.id] = integration;
					return dict;
				}, {});
			}
			return this.integrationsById[id];
		},

		pages: function(){
			return this.get('document.pages');
		},

		// page: function(page){
		// 	if ( page < 1 || page > this.pages() ) return null;
		// 	// TODO: For the future, try to avoid using the page width/height
		// 	// If we can manage to do this, we should be able to remove the tbl_book_pages DB table completely
		// 	// And move the convertion to AWS if necessary (links and TOC will be store directly on S3)
		// 	// this.get('pages')[page-1].w = this.get('document.width');
		// 	// this.get('pages')[page-1].h = this.get('document.height');
		// 	return this.get('pages')[page-1];
		// },

		direction: function(){
			return this.get('document.direction');
		},

		view: function(){
			return LoaderConfigSrvc.get('view') || LoaderConfigSrvc.get('viewModeAtStart') || this.get('document.view');
		},

		hasAd: function(){
			return this.feature('ads.enabled');
		},

		shareURL: function(){
			var url;

			// FREE get the overview page before the view page if public, otherwise the view page directly
			if ( this.account('mode') == $C.ACCOUNT_MODE_FREE )
			{
				url = this.get('mode') == $C.PUBLISH_MODE_PRIVATE ? this.get('url.view') : this.get('url.public');
			}
			// PLATINUM get the shareURL parameter before the view page
			else if ( this.account('mode') == $C.ACCOUNT_MODE_PLATINUM )
			{
				url = LoaderConfigSrvc.get('shareurl') || this.get('url.view');
			}
			// PREMIUM get the view URL and that's it
			else
			{
				url = this.get('url.view')
			}

			return url;
		},

		getDownloadUrl: function(){
			var url = URLObject( this.feature('download.url') );
			var params = [];
			_.each( BookConfigSrvc.get(), function(value, key){
				params.push([key, encodeURIComponent(value)]);
			} );
			url.query(params);
			return Util.url.prepare( url.toString() );
		},

		// getSwfUrl: function(page)
		// {
		// 	var p = this.page(page);

		// 	if ( _.isEmpty(p) ) return Util.EmptyPNG;

		// 	return Util.url.prepare( this.get('domains.pages') + this.get('key') + '/' + p.p.u );
		// },
		getImageUrl: function(page)
		{
			if ( page < 1 || page > this.pages() ) return Util.EmptyPNG;

			var domain = !this.feature('urlsigning.enabled') ? this.get('domains.image') : this.get('domains.secured.image');

			var url = Util.url.prepare( domain + this.get('key') + '/p' + page + '.jpg' );

			return this.signUrl(url);
			/*
			var p = this.page(page);

			if ( _.isEmpty(p) ) return Util.EmptyPNG;

			return Util.url.prepare( this.get('domains.image') + this.get('key') + '/' + p.i.u );
			*/
		},
		getSvgUrl: function (page)
		{
			if ( page < 1 || page > this.pages() ) return Util.EmptyPNG;

			var domain = !this.feature('urlsigning.enabled') ? this.get('domains.svg') : this.get('domains.secured.svg');

			var url = Util.url.prepare( domain + this.get('key') + '/p' + page + '.svgz' );

			return this.signUrl(url);
			/*
			var p = this.page(page);

			if ( _.isEmpty(p) ) return false;

			return Util.url.prepare( this.get('domains.svg') + 'p' + page + '.svg' ); // + p.s.u;
			*/
		},
		getThumbUrl: function(page)
		{
			if ( page < 1 || page > this.pages() ) return Util.EmptyPNG;

			return Util.url.prepare( this.get('domains.thumbnail') + this.get('key') + '/p' + page + '.jpg' );
			/*
			var p = this.page(page);

			if ( _.isEmpty(p) ) return Util.EmptyPNG;

			return Util.url.prepare( this.get('domains.thumbnail') + this.get('key') + '/' + p.t.u );
			*/
		},
		isOdd: function () {
			return this.pages() % 2 !== 0;
		},

		signUrl: function(url) {
			if (!this.feature('urlsigning.enabled')) {
				return url;
			}

			var token = [
				'exp=' + this._expires,
				'acl=' + this._path,
				'hmac=' + this._signature
			];

			return url + ( url.indexOf('?') >= 0 ? '&' : '?' ) + '_token_=' + token.join('~');
		},

		getIntegrationContext: function() {
			var url				= this.get('url');
			var account			= this.get('account');
			var subscription	= this.get('subscription');
			var doc				= this.get('document');
			var subscriber      = this.get('subscriber');

			return {
				book: {
					name: this.get('name'),
					id: this.get('id'),
					mode: this.get('mode'),
					url: url
				},
				account: {
					name: account.name,
					id: account.id,
					url: {
						overview: account.url
					},
					type: {
						id: account.type,
						mode: account.mode
					},
					is_publisher: this.getCurrentUserAccountID() === account.id
				},
				subscription: {
					name: subscription.name,
					id: subscription.id,
					url: {
						overview: subscription.url
					}
				},
				document: {
					width: doc.width,
					height: doc.height,
					pages: doc.pages,
					direction: doc.direction === 'left' ? 'ltr' : 'rtl'
				},
				subscriber: subscriber,
				tracker_source: LoaderConfigSrvc.get('trackersource'),
				consent: {
					cookies: LoaderConfigSrvc.get('cookies'),
					tracking: !LoaderConfigSrvc.get('disablega') && !LoaderConfigSrvc.get('disableaccountga') && !LoaderConfigSrvc.get('disabletracking')
				},
				campaign: {
					id: LoaderConfigSrvc.get('utm_id') || undefined,
					source: LoaderConfigSrvc.get('utm_source') || undefined,
					medium: LoaderConfigSrvc.get('utm_medium') || undefined,
					name: LoaderConfigSrvc.get('utm_campaign') || undefined,
					term: LoaderConfigSrvc.get('utm_term') || undefined,
					content: LoaderConfigSrvc.get('utm_content') || undefined
				}
			};
		},

		getTriggerContext: function(trigger) {
			var context = this.getIntegrationContext();
			context['trigger'] = {
				type: trigger.type,
				is_mandatory: trigger.is_mandatory,
				repeating_mode: trigger.repeating_mode
			};
			return context;
		}
	});

});

